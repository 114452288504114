import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Role } from '../../../shared/enums/role.enum';
import { ScreenType } from '../../../shared/enums/screen-type.enum';
import { DecodedTokenI } from '../../../shared/interfaces/decoded-token.interface';
import { UserI } from '../../../shared/interfaces/user.interface';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  defaultUser: UserI = {
    nameAr: 'مطلك العظيم',
    nameEn: 'The Great Mutlaq',
    email: 'mutlaq@gmail.com',
    permission: {
      academicYear: [],
      course: [],
      courseSection: [],
      department: [],
      faculty: [],
      program: [],
      semester: [],
      semesterActivities: [],
    },
    roles: [Role.SUPER_ADMIN],
    profilePicture: {
      solutionID: '',
      fileName: 'Mutlaq',
      format: 'png',
      url: '',
    },
    _id: '',
  };

  private readonly loggedInUser: BehaviorSubject<UserI> = new BehaviorSubject(
    this.defaultUser
  );

  private readonly isSideMenuOpen: Subject<boolean> = new Subject();

  private readonly screenType: Subject<ScreenType> = new Subject();

  constructor(private readonly storageService: StorageService) {}

  addLoggedInUser(user: UserI) {
    this.loggedInUser.next(user);
  }

  getLoggedInUser$() {
    return this.loggedInUser.asObservable();
  }

  addIsSideMenuOpen(isSideMenuOpen: boolean) {
    this.isSideMenuOpen.next(isSideMenuOpen);
  }

  getIsSideMenuOpen$() {
    return this.isSideMenuOpen.asObservable();
  }

  addScreenType(screenType: ScreenType) {
    this.screenType.next(screenType);
  }

  getScreenType$() {
    return this.screenType.asObservable();
  }

  getDecodedToken(): DecodedTokenI {
    return JSON.parse(atob(this.storageService.accessToken?.split('.')[1]!));
  }
}
